<template>
  <div>
    <b-row class="my-4 mb-5">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          Push Notification
        </h3>

        <router-link :to="{ name: 'general-push' }">
          <b-button size="sm" class="ml-3" variant="info">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-backspace-fill mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241
                7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2
                2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147
                2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0
                1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
                8 5.829 5.854z"
              />
            </svg>

            {{ $t('buttons.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit">
      <b-row class="pb-4">
        <b-col lg="2" sm="12" class="mb-3">
          <h5>{{ $t('inputs.credential') }}</h5>

          <b-form-checkbox
            id="sel-credential-all"
            v-model="credentialAllSelected"
            :value="true"
            :unchecked-value="false"
            @change.native="selectAllCredentials"
          >
            {{ $t('inputs.all') }}
          </b-form-checkbox>

          <b-form-group>
            <b-form-checkbox-group
              id="checkbox-group-cred"
              v-model="credentialsSelected"
              :options="credentialsArr"
              name="sel-genres"
              @change.native="onChangeCredential"
              stacked
            />
          </b-form-group>
        </b-col>

        <b-col lg="2" sm="12" class="mb-3">
          <h5>{{ $t('pushNotification.ageRange') }}</h5>

          <b-form-checkbox
            id="sel-age-all"
            v-model="ageAllSelected"
            :value="true"
            :unchecked-value="false"
            @change.native="selectAllAges"
          >
            {{ $t('inputs.all') }}
          </b-form-checkbox>

          <b-form-group>
            <b-form-checkbox-group
              id="checkbox-group-age"
              v-model="agesSelected"
              :options="agesArr"
              name="sel-age"
              @change.native="onChangeAge"
              stacked
            />
          </b-form-group>
        </b-col>

        <b-col lg="3" sm="12" class="mb-3">
          <h5>{{ $t('pushNotification.genders') }}</h5>

          <b-form-checkbox
            id="sel-genres-all"
            v-model="genresAllSelected"
            :value="true"
            :unchecked-value="false"
            @change.native="selectAllGenres"
          >
            {{ $t('inputs.all') }}
          </b-form-checkbox>

          <b-form-group>
            <b-form-checkbox-group
              id="checkbox-group-genre"
              v-model="genresSelected"
              :options="genresArr"
              name="sel-genres"
              @change.native="onChangeGenre"
              stacked
            />
          </b-form-group>
        </b-col>

        <b-col lg="2" sm="12" class="mb-3">
          <h5>{{ $t('pushNotification.stages') }}</h5>

          <b-form-checkbox
            id="sel-stage-all"
            v-model="stagesAllSelected"
            :value="true"
            :unchecked-value="false"
            @change.native="selectAllStages"
          >
            {{ $t('inputs.all') }}
          </b-form-checkbox>

          <b-form-group>
            <b-form-checkbox-group
              id="checkbox-group-stage"
              v-model="stageSelected"
              :options="stagesArr"
              name="sel-stages"
              @change.native="onChangeStage"
              stacked
            />
          </b-form-group>
        </b-col>

        <b-col lg="2" sm="12" class="mb-3">
          <h5>{{ $t('pushNotification.uf') }}</h5>

          <b-form-checkbox
            id="sel-picture-all"
            v-model="statesAllSelected"
            :value="true"
            :unchecked-value="false"
            @change.native="selectAllStates"
          >
            {{ $t('inputs.all') }}
          </b-form-checkbox>

          <b-form-group>
            <b-form-checkbox-group
              id="checkbox-group-picture"
              v-model="statesSelected"
              :options="stateArr"
              name="sel-pictures"
              @change.native="onChangeState"
              stacked
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="border-bottom mb-4">
        <b-col lg="12">
          <h6 class="mb-4">
            <strong class="d-flex align-items-center">
              {{ $t('pushNotification.customersReached') }}:
              <b-spinner
                v-if="reachedUsersLoading"
                class="ml-2"
                small
                variant="primary"
              />
              <span v-else class="ml-2">~{{ reachedUsers }}</span>
            </strong>
          </h6>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="7" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.titlePT')" class="mb-3">
            <b-form-input
              v-model="model.titlePT"
              maxlength="60"
              type="text"
              required
            />
          </b-input-group>

          <b-input-group :prepend="$t('inputs.titleEN')" class="mb-3">
            <b-form-input v-model="model.titleEN" maxlength="60" type="text" />
          </b-input-group>

          <b-input-group prepend="URL" class="mb-3">
            <b-form-input v-model="model.link" type="url" required />
          </b-input-group>

          <TextArea
            :label="$t('pushNotification.messagePT')"
            :placeholder="$t('placeholder.messagePT')"
            v-model="model.messagePT"
            :required="true"
            maxlength="500"
          />

          <TextArea
            :label="$t('pushNotification.messageEN')"
            :placeholder="$t('placeholder.messageEN')"
            v-model="model.messageEN"
            maxlength="500"
          />

          <b-input-group prepend="Tipo" class="mb-3">
            <b-form-select v-model="model.type" :options="typesArr" required />
          </b-input-group>
          <b-row align-v="center">
            <b-col align-self="center" lg="6" sm="12" class="mb-3">
              <b-form-checkbox
                class="mr-3"
                id="sel-customer-percentage"
                v-model="customerPercentageSelected"
                :value="true"
                :unchecked-value="false"
                @change.native="onChangeLimit(true)"
              >
                {{ $t('pushNotification.limitByPercent') }}
              </b-form-checkbox>
              <b-form-input
                v-if="customerPercentageSelected"
                id="customer-percentage-value"
                v-model="customersPercentage"
                type="number"
                min="0"
                max="100"
              >
              </b-form-input>
            </b-col>
            <b-col lg="6" sm="12" class="mb-3">
              <b-form-checkbox
                class="mr-3"
                id="sel-customers-qty"
                v-model="customersQtySelected"
                :value="true"
                :unchecked-value="false"
                @change.native="onChangeLimit(false)"
              >
                {{ $t('pushNotification.limitByN') }}
              </b-form-checkbox>
              <b-form-input
                v-if="customersQtySelected"
                id="customers-customers-qty"
                v-model="customersQty"
                type="number"
              >
              </b-form-input>
            </b-col>
          </b-row>
          <label>{{ $t('pushNotification.scheduledTo') }}</label>
          <b-row class="mb-3">
            <b-col lg="6" sm="12" class="mb-3">
              <b-input-group :prepend="$t('pushNotification.date')">
                <b-form-input
                  input-class="form-control"
                  id="customers-date"
                  v-model="model.date"
                  type="date"
                  required
                >
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="6" sm="12" class="mb-3">
              <b-input-group :prepend="$t('pushNotification.time')">
                <b-form-input
                  input-class="form-control"
                  id="customers-time"
                  v-model="model.time"
                  type="time"
                  required
                >
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-row>

          <b-form-group :label="$t('pushNotification.pushImage')" class="mb-2">
            <ImageUploader
              minWidth="100"
              minHeight="100"
              v-model="upload"
              ref="imageRef"
            />
          </b-form-group>
        </b-col>

        <b-col lg="5" sm="12" class="mb-3" v-if="upload.preview">
          <img
            :src="upload.preview"
            class="preview mb-1"
            style="max-width: 100%"
          />
          <div>
            <a href="javascript:void(0)" class="mx-2" @click="changePicture()">
              {{ $t('inputs.changePicture') }}
            </a>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="5" sm="12" class="mb-3">
          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { DateTime as luxon } from 'luxon';
import GeneralPush from '../../../../services/GeneralPush';
import GeneralCommon from '../../../../services/GeneralCommon';
import ImageUploader from '../../../../components/organisms/ImageUploader.vue';
import TextArea from '../../../../components/organisms/TextArea.vue';
import Toast from '../../../../assets/js/toast';

export default {
  components: {
    TextArea,
    ImageUploader,
  },

  data() {
    const data = {
      isEdit: false,
      loaded: false,
      loading: true,
      genders: [],
      states: [],
      stages: [],
      genresSelected: [],
      genresAllSelected: true,
      agesSelected: [],
      ageAllSelected: true,
      credentialsSelected: [],
      credentialAllSelected: true,

      statesSelected: [],
      statesAllSelected: true,

      stageSelected: [],
      stagesAllSelected: true,

      reachedUsers: '',
      reachedUsersLoading: true,

      confirmModal: false,

      customerPercentageSelected: false,
      customersQtySelected: false,
      customersPercentage: 0,
      customersQty: 0,

      date: '',
      time: '',

      upload: {
        file: null,
        preview: null,
        blob: null,
      },

      model: {
        titlePT: '',
        titleEN: '',
        messagePT: '',
        messageEN: '',
        link: Vue.prototype.VUE_APP_FRONT_URL,
        image: '',
        type: null,
        ufs: [],
        stages: [],
        genders: [],
        credentials: [],
        ages: [],
        date: '',
        time: '',
      },
    };

    return data;
  },

  beforeMount() {
    this.initPage();
  },

  methods: {
    async initPage() {
      await this.onLoad();
      this.loading = false;
      this.loaded = true;

      if (!this.isEdit) {
        this.selectAllGenres(false);
        this.selectAllAges(false);
        this.selectAllCredentials(false);
        this.selectAllStages(false);
        this.selectAllStates(false);
      }

      this.countCustomers();
    },

    async onLoad() {
      this.genders = await GeneralCommon.genders();
      this.states = await GeneralCommon.states();
      this.stages = await GeneralCommon.stages();

      await this.onLoadPushNotification();

      return true;
    },

    async onLoadPushNotification() {
      try {
        this.loading = true;
        const { id } = this.$route.params;

        if (id && Number(id)) {
          const xhr = await GeneralPush.getById(id);
          this.model = {
            ...xhr,
          };

          if (xhr.customersPercentage > 0) {
            this.customerPercentageSelected = true;
            this.customersPercentage = xhr.customersPercentage;
          }
          if (xhr.customersQty > 0) {
            this.customersQtySelected = true;
            this.customersQty = xhr.customersQty;
          }
          this.model.time = luxon
            .fromISO(xhr.scheduledTo)
            .setLocale(this.$i18n.locale)
            .toLocaleString(luxon.TIME_SIMPLE);

          this.model.date = luxon
            .fromISO(xhr.scheduledTo)
            .setLocale(this.$i18n.locale)
            .toISODate();

          this.initFilters(xhr);

          if (xhr.genders.length === 0) {
            this.selectAllGenres(false);
            this.genresAllSelected = true;
          }

          if (xhr.ages.length === 0) {
            this.selectAllAges(false);
            this.ageAllSelected = true;
          }

          if (xhr.stages.length === 0) {
            this.selectAllStages(false);
            this.stagesAllSelected = true;
          }

          if (xhr.ufs.length === 0) {
            this.selectAllStates(false);
            this.statesAllSelected = true;
          }

          if (xhr.credentials.length === 0) {
            this.selectAllCredentials(false);
            this.credentialAllSelected = true;
          }

          if (xhr.image) {
            this.upload.preview = `${Vue.prototype.VUE_APP_S3_URL}/push/${xhr.image}`;
          }
          this.isEdit = true;

          this.countCustomers();
        }

        return true;
      } catch (error) {
        Sentry.captureException(error);
        Toast.error(this, error);
        return false;
      }
    },

    async countCustomers() {
      try {
        this.reachedUsersLoading = true;
        const model = {
          genders: this.genresAllSelected ? [] : this.genresSelected,
          ages: this.ageAllSelected ? [] : this.agesSelected,
          credentials: this.credentialAllSelected
            ? []
            : this.credentialsSelected,
          ufs: this.statesAllSelected ? [] : this.statesSelected,
        };

        const { count } = await GeneralPush.count(model);
        this.reachedUsers = count;
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra(
            'genresSelected',
            JSON.stringify(this.genresSelected, undefined, 2),
          );
          scope.setExtra(
            'agesSelected',
            JSON.stringify(this.agesSelected, undefined, 2),
          );
          scope.setExtra(
            'credentialsSelected',
            JSON.stringify(this.credentialsSelected, undefined, 2),
          );
          scope.setExtra(
            'satesSelected',
            JSON.stringify(this.statesSelected, undefined, 2),
          );
          scope.setExtra(
            'stagesSelected',
            JSON.stringify(this.stagesSelected, undefined, 2),
          );
          Sentry.captureException(e);
        });
      } finally {
        this.reachedUsersLoading = false;
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        const action = this.isEdit ? 'update' : 'save';

        const fileName = await this.onUploadImage();

        const model = {
          ...this.model,
          customersPercentage: Number(this.customersPercentage),
          customersQty: Number(this.customersQty),
          genders: this.genresAllSelected ? [] : this.genresSelected,
          ages: this.ageAllSelected ? [] : this.agesSelected,
          credentials: this.credentialAllSelected
            ? []
            : this.credentialsSelected,
          stages: this.stagesAllSelected ? [] : this.stageSelected,
          ufs: this.statesAllSelected ? [] : this.statesSelected,
          image: fileName || this.model.image,
          scheduledTo: this.formatDate(`${this.model.date} ${this.model.time}`),
        };

        await GeneralPush[action](model, this.model.id);

        Toast.success(this, 'messages.saveSuccess');
        setTimeout(() => {
          this.$router.push({ name: 'general-push' });
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);

        this.loading = false;
      }
    },

    async onUploadImage() {
      try {
        if (this.upload.file) {
          const fd = new FormData();
          fd.append('file', this.upload.blob, this.upload.file.name);
          const objPic = await GeneralPush.upload(fd);

          return objPic.filename;
        }

        return '';
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('upload push', 'error');
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        return '';
      }
    },

    changePicture() {
      this.$refs.imageRef.changeFile();
    },

    selectAllGenres(count = true) {
      if (this.genresAllSelected) {
        this.genresSelected = this.genresArr.map((item) => item.value);
      } else {
        this.genresSelected = [];
      }
      if (count) this.countCustomers();
    },

    selectAllAges(count = true) {
      if (this.ageAllSelected) {
        this.agesSelected = this.agesArr.map((item) => item.value);
      } else {
        this.agesSelected = [];
      }
      if (count) this.countCustomers();
    },

    selectAllCredentials(count = true) {
      if (this.credentialAllSelected) {
        this.credentialsSelected = this.credentialsArr.map((item) => item.value);
      } else {
        this.credentialsSelected = [];
      }
      if (count) this.countCustomers();
    },

    selectAllStates(count = true) {
      if (this.statesAllSelected) {
        this.statesSelected = this.stateArr.map((item) => item.value);
      } else {
        this.statesSelected = [];
      }
      if (count) this.countCustomers();
    },

    selectAllStages(count = true) {
      if (this.stagesAllSelected) {
        this.stageSelected = this.stagesArr.map((item) => item.value);
      } else {
        this.stageSelected = [];
      }
      if (count) this.countCustomers();
    },

    initFilters(xhr) {
      this.agesSelected = xhr.ages;
      this.ageAllSelected = false;

      this.statesSelected = xhr.ufs;
      this.statesAllSelected = false;

      this.credentialsSelected = xhr.credentials;
      this.credentialAllSelected = false;

      this.stageSelected = xhr.stages;
      this.stagesAllSelected = false;

      this.genresSelected = xhr.genders;
      this.genresAllSelected = false;
    },

    onChangeGenre() {
      this.genresAllSelected = this.genresSelected.length === this.genresArr.length;
      this.countCustomers();
    },

    onChangeAge() {
      this.ageAllSelected = this.agesSelected.length === this.agesArr.length;
      this.countCustomers();
    },

    onChangeCredential() {
      this.credentialAllSelected = this.credentialsSelected.length === this.credentialsArr.length;
      this.countCustomers();
    },

    onChangeState() {
      this.statesAllSelected = this.statesSelected.length === this.stateArr.length;
      this.countCustomers();
    },

    onChangeStage() {
      this.stagesAllSelected = this.stagesSelected.length === this.stagesArr.length;
      this.countCustomers();
    },
    onChangeLimit(byPercent) {
      if (this.customersQtySelected && !byPercent) {
        this.customersPercentage = 0;
        this.customerPercentageSelected = false;
      }
      if (this.customerPercentageSelected && byPercent) {
        this.customersQty = 0;
        this.customersQtySelected = false;
      }
    },

    formatDate(date) {
      try {
        return luxon.fromSQL(date).setLocale(this.$i18n.locale);
      } catch (e) {
        Toast.error(e);
        return date;
      }
    },
  },

  computed: {
    credentialsArr() {
      return [
        { text: 'Free', value: 'free experience' },
        { text: 'Digital', value: 'digital experience' },
        { text: 'Home', value: 'home experience' },
      ];
    },

    agesArr() {
      return [
        { text: '0-17', value: '0-17' },
        { text: '18-24', value: '18-24' },
        { text: '25-34', value: '25-34' },
        { text: '35-44', value: '35-44' },
        { text: '45+', value: '45+' },
      ];
    },

    genresArr() {
      return this.genders.map((item) => ({
        text: this.$i18n.locale === 'en' ? item.nameEN : item.namePT,
        value: item.id,
      }));
    },

    stateArr() {
      return this.states.map((state) => ({ text: state.name, value: state.id }));
    },

    stagesArr() {
      return this.stages.map((stage) => ({ text: stage.name, value: stage.id }));
    },
    typesArr() {
      return [
        { value: null, text: 'Selecione' },
        { value: 'COMMUNICATION', text: 'Communication' },
        { value: 'COMERCIAL', text: 'Comercial' },
        { value: 'DROP', text: 'Drop' },
      ];
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
